import { PropsWithChildren, createContext, useContext } from 'react';
import ContactUsFormDialog from 'ls-common-client/src/components/ContactUsFormDialog';
import useGlobalContextValues, { GlobalContextValue } from './hooks';
import { TRACKING } from '../../lib/constants';

const Context = createContext<GlobalContextValue | Record<string, never>>({});

const Provider = ({ children }: PropsWithChildren) => {
  const value = useGlobalContextValues();
  const {
    contactUsDialog: { defaultValues, showDialog, close, profileId },
    media: { mobile },
  } = value;

  return (
    <Context.Provider value={value}>
      {children}
      <ContactUsFormDialog
        show={showDialog}
        onSubmitSuccess={() => close()}
        onClose={() => close()}
        mode={mobile ? 'mobile' : 'desktop'}
        profileId={profileId}
        defaultValues={defaultValues}
        form_type={TRACKING.FORM_TYPES.CONTACT_US}
      />
    </Context.Provider>
  );
};

/**
 * Provides global context down the component tree.
 * @category Context Hooks
 * @example
 * const {
 *   theme,
 *   media,
 *   notifications,
 *   accountProfileSearch,
 *   sideBar,
 * } = useGlobalContext();
 */
function useGlobalContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useGlobalContext must be used within a GlobalContext Provider'
    );
  }

  return context;
}

export default useGlobalContext;
export { Provider, Context };
