import { createContext, PropsWithChildren, useContext, useRef } from 'react';
import Container from 'ls-common-client/src/components/Container';
import NotificationSlider from 'ls-common-client/src/components/NotificationSlider';
import useManageProfileValues, { ManageProfileValues } from './hooks';
import { NotificationsContextProvider } from '../NotificationsContext';

const Context = createContext<ManageProfileValues | Record<string, never>>({});

const Provider = ({ children }: PropsWithChildren) => {
  const portalRef = useRef();
  const value = useManageProfileValues();

  return (
    <Context.Provider value={value}>
      <NotificationsContextProvider>
        {children}

        {value.notificationSlider.notifications.map(props => (
          <NotificationSlider
            mode={value.media.mobile ? 'mobile' : 'desktop'}
            zIndex="10"
            portalElement={portalRef.current}
            position="relative"
            width={value.media.mobile ? '100%' : '465px'}
            {...props}
          />
        ))}
        <Container
          ref={portalRef}
          position="fixed"
          zIndex="101"
          bottom="0"
          left="0"
          right="0"
          width={value.media.mobile ? undefined : '30%'}
        />
      </NotificationsContextProvider>
    </Context.Provider>
  );
};

/**
 * Provides managed profile context down the component tree.
 * @category Context Hooks
 * @example
 * const {
 *   profile,
 *   notifications,
 *   userProfiles,
 * } = useManageProfileContext();
 */
function useManageProfileContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useManageProfileContext must be used within a MangeProfileContext Provider'
    );
  }

  return context;
}

export default useManageProfileContext;
export { Provider, Context };
