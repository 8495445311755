import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Context as GlobalContext } from '../GlobalContext';

const Context = createContext();

const Provider = ({ children }) => {
  const globalValues = useContext(GlobalContext);

  const value = useMemo(
    () => ({
      ...globalValues,
    }),
    [globalValues]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export { Provider, Context };
