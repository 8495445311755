import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, matchPath, useNavigate } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { AWS_ACCOUNT_ROLES, ROLES } from '../../../lib/constants';

const { STAFF } = ROLES;
const { ADMIN, OWNER } = AWS_ACCOUNT_ROLES;

const routeAccessRules = [
  { path: '/', accessRoles: [STAFF, OWNER, ADMIN] },
  { path: '/staff/:awsAccountId', accessRoles: [STAFF, OWNER, ADMIN] },
];

const hasRoles = (userRoles: string[] = [], roles: string[] = []) =>
  userRoles.some(roleName => roles.some(role => roleName.startsWith(role)));

const useAuthorisation = () => {
  const { user, isStaff, isPersoniv } = useUser();

  const { awsAccountRoles, profileRoles } = user || {};

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { awsAccountId } = useParams();
  const [isAuthorised, setIsAuthorised] = useState(false);

  const allAccessRoles = useMemo(() => {
    const { edges: userRoles } = user?.userRoles || {};

    const awsAccountRoleNames =
      awsAccountRoles?.map(role => role?.node?.awsAccountRoleName) || [];

    const userRoleNames = userRoles?.map(role => role?.node?.roleName) || [];

    return [...awsAccountRoleNames, ...userRoleNames];
  }, [user, awsAccountRoles]);

  useEffect(() => {
    const authoriseUser = async () => {
      if (!awsAccountRoles && !profileRoles) {
        return;
      }

      if (isPersoniv) {
        navigate('/profile');
        return;
      }

      if (isStaff) {
        setIsAuthorised(true);
        setLoading(false);
        return;
      }

      const hasAwsAccountAccess = !!awsAccountRoles?.length;
      const hasProfileAccess = !!profileRoles?.length;

      if (hasAwsAccountAccess && !awsAccountId) {
        const firstAwsAccountRole = awsAccountRoles[0];
        const { awsAccountId: id } = firstAwsAccountRole?.node || {};
        navigate(`${id}`);
        return;
      }

      if (hasAwsAccountAccess && awsAccountId) {
        const authorised = awsAccountRoles.some(
          awsAccountRole => awsAccountRole?.node?.awsAccountId === awsAccountId
        );
        if (authorised) {
          setIsAuthorised(authorised);
          setLoading(false);
          return;
        }
      }

      if (hasProfileAccess) {
        const firstProfileRole = profileRoles[0];
        const { profileId } = firstProfileRole?.node || {};

        navigate(`/profile/${profileId}`);
        setLoading(false);
      }

      setLoading(false);
    };

    authoriseUser();
  }, [awsAccountRoles, profileRoles, awsAccountId, isStaff, isPersoniv]);

  const hasRouteAccess = useCallback(
    (route: string) => {
      const { accessRoles } =
        routeAccessRules.find(({ path }: { path: string }) =>
          matchPath(path, route)
        ) || {};

      if (!accessRoles) {
        return true;
      }

      return hasRoles(allAccessRoles, accessRoles);
    },
    [allAccessRoles]
  );

  return {
    loading,
    isAuthorised,
    hasRouteAccess,
  };
};

export default useAuthorisation;
