import { useCallback, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { ProfileDTO } from '../../../types';

const useContactUsDialog = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [showDialog, setShowDialog] = useState(queryParams.has('contact-us'));
  const [profileId, setProfileId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const { user } = useUser();

  let emailId;
  let fName;
  let phone;
  if (user && user.account) {
    const {
      account: { email, firstName, phoneNumber },
    } = user;
    emailId = email;
    fName = firstName;
    phone = phoneNumber;
  } else {
    emailId = '';
    fName = '';
    phone = '';
  }

  const open = useCallback(
    ({ profile }: { profile: ProfileDTO }) => {
      setShowDialog(true);
      setProfileId(profile.id);
      setAddress(profile.street);
      setCompanyName(profile.displayName);
    },
    [showDialog]
  );

  const close = useCallback(() => {
    setShowDialog(false);
  }, [showDialog]);

  const defaultValues = {
    message: '',
    companyName,
    email: emailId,
    address,
    phone,
    firstName: fName,
  };

  return {
    showDialog,
    defaultValues,
    open,
    close,
    profileId,
  };
};

export default useContactUsDialog;
