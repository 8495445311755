import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  Navigate,
  useParams,
} from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Provider as ManageAccountProvider } from '../../context/ManageAccountContext';
import { Provider as ManageProfileProvider } from '../../context/ManageProfileContext';
import { Provider as CreateProfileProvider } from '../../context/CreateProfileContext';
import { Provider as ClaimProfileProvider } from '../../context/ClaimProfileContext';
import accountRoutes from './account';
import profileRoutes from './profile';
import config from '../../config';

const CreateProfile = lazy(() => import('../pages/CreateProfile'));
const ClaimProfile = lazy(() => import('../pages/ClaimProfile'));
const AssetUploader = lazy(() => import('../pages/AssetUploader'));
const CMSBatchUploader = lazy(() => import('../pages/CMSBatchUploader'));
const ManageAccount = lazy(() => import('../templates/ManageAccount'));
const ManageProfile = lazy(() => import('../templates/ManageProfile'));

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.env,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', config.api],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: config.env === 'production' ? 0.1 : 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const ProfileRedirect = ({ to }: { to: string }) => {
  const { profileId } = useParams();
  return <Navigate replace to={`/profile/${profileId}/${to}`} />;
};

const router = sentryCreateBrowserRouter([
  {
    path: 'create-profile',
    element: (
      <CreateProfileProvider>
        <Suspense>
          <CreateProfile />
        </Suspense>
      </CreateProfileProvider>
    ),
  },
  {
    path: 'claim-profile/:profileId',
    element: (
      <ClaimProfileProvider>
        <Suspense>
          <ClaimProfile />
        </Suspense>
      </ClaimProfileProvider>
    ),
  },
  {
    path: 'asset-uploader',
    element: (
      <Suspense>
        <AssetUploader />
      </Suspense>
    ),
  },
  {
    path: 'cms-batch-uploader',
    element: (
      <Suspense>
        <CMSBatchUploader />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: (
      <ManageAccountProvider>
        <Suspense>
          <ManageAccount />
        </Suspense>
      </ManageAccountProvider>
    ),
    children: accountRoutes,
  },
  {
    path: 'profile',
    element: (
      <ManageProfileProvider>
        <Suspense>
          <ManageProfile />
        </Suspense>
      </ManageProfileProvider>
    ),
    children: profileRoutes,
  },
  {
    path: 'info/:profileId',
    element: <ProfileRedirect to="info" />,
  },
  {
    path: 'reviews/:profileId',
    element: <ProfileRedirect to="reviews" />,
  },
  {
    path: 'quotes/:profileId',
    element: <ProfileRedirect to="inbox/quotes" />,
  },
  {
    path: 'enquiries/:profileId',
    element: <ProfileRedirect to="inbox/messages" />,
  },
  {
    path: 'posts/:profileId',
    element: <ProfileRedirect to="posts/enabled" />,
  },
  {
    path: 'media/:profileId',
    element: <ProfileRedirect to="media/logo" />,
  },
  {
    path: 'insights/:profileId',
    element: <ProfileRedirect to="performance/google-ads" />,
  },
  {
    path: 'history/:profileId',
    element: <ProfileRedirect to="history" />,
  },
]);

export default router;
