import React from 'react';
import ReactDom from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import mapboxgl from 'mapbox-gl';
import mapConfig from 'ls-map/src/map/config';
import App from './components/App';
import logger from './services/logger';
import auth from './services/auth';
import client from './services/client';
import config from './config';
import 'mapbox-gl/dist/mapbox-gl.css';
import { gtmScript } from './lib/helmetScripts';

const {
  googleMapsApiKey,
  lsDesignIconRoot,
  lsBusinessProfileIconRoot,
  optimizely,
} = config;

const { accessToken } = mapConfig;

mapboxgl.accessToken = accessToken;

logger.info(config);

const Root = () => (
  <ApolloProvider client={client}>
    <HelmetProvider>
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
          type="text/javascript"
        />
        <script>{gtmScript}</script>
        <script src={optimizely} />
        <link rel="stylesheet" href={lsBusinessProfileIconRoot} />
        <link rel="stylesheet" href={lsDesignIconRoot} />
      </Helmet>
      <App />
    </HelmetProvider>
  </ApolloProvider>
);

const render = async () => {
  const token = await auth.init({
    loginOptions: {
      screen_hint:
        window.location.pathname.includes('/create-profile') ||
        window.location.pathname.includes('/claim-profile')
          ? 'signup'
          : undefined,
    },
    onRedirect: url => {
      window.location.replace(url);
    },
  });
  if (token) {
    const root = ReactDom.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <Root />
      </React.StrictMode>
    );
  }
};

render();
