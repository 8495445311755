import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';

const PageLoader = (props: any) => {
  return (
    <Container
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
      width="100%"
      height="100%"
      {...props}
    >
      <Loader width="200px" />
    </Container>
  );
};

export default PageLoader;
