import { NonIndexRouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../../UI/molecules/PageLoader';

const Enquiries = lazy(() => import('../../../pages/Profile/Inbox/Enquiries'));
const Quotes = lazy(() => import('../../../pages/Profile/Inbox/Quotes'));

const inboxRoutes: NonIndexRouteObject[] = [
  {
    path: 'messages',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Enquiries />
      </Suspense>
    ),
  },
  {
    path: 'enquiries',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Enquiries />
      </Suspense>
    ),
  },
  {
    path: 'quotes',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Quotes />
      </Suspense>
    ),
  },
];

export default inboxRoutes;
