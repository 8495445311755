import useAuthorisation from './useAuthorisation';
import useProfile from '../../../hooks/useProfile';
import useUpgradeDialog from './useUpgradeDialog';
import useNotificationSlider from './useNotificationSlider';
import useUser from '../../../hooks/useUser';
import useGlobalContext from '../../GlobalContext';

const useManageProfileValues = () => {
  const { media, sideBar, contactUsDialog, ...globalValues } =
    useGlobalContext();
  const user = useUser();
  const profile = useProfile();
  const authorisation = useAuthorisation();
  const upgradeDialog = useUpgradeDialog();
  const notificationSlider = useNotificationSlider();

  return {
    ...globalValues,
    media,
    sideBar,
    user,
    authorisation,
    profile,
    upgradeDialog,
    notificationSlider,
    contactUsDialog,
  };
};

export default useManageProfileValues;
export type ManageProfileValues = ReturnType<typeof useManageProfileValues>;
