import { NonIndexRouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../../UI/molecules/PageLoader';
import { LISTING_POST_STATES } from '../../../../lib/constants';

const Posts = lazy(() => import('../../../pages/Profile/Posts/Posts'));

const { ENABLED, DISABLED } = LISTING_POST_STATES;

const postsRoutes: NonIndexRouteObject[] = [
  {
    path: ENABLED,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Posts state={ENABLED} />
      </Suspense>
    ),
  },
  {
    path: DISABLED,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Posts state={DISABLED} />
      </Suspense>
    ),
  },
];

export default postsRoutes;
