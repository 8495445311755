import { NonIndexRouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../../UI/molecules/PageLoader';

const LocalsearchPlus = lazy(
  () => import('../../../pages/Profile/Performance/LocalsearchPlus')
);
const GoogleAds = lazy(
  () => import('../../../pages/Profile/Performance/GoogleAds')
);
const SEO = lazy(() => import('../../../pages/Profile/Performance/SEO'));
const SocialMedia = lazy(
  () => import('../../../pages/Profile/Performance/SocialMedia')
);
const Websites = lazy(
  () => import('../../../pages/Profile/Performance/Websites')
);

const performanceRoutes: NonIndexRouteObject[] = [
  {
    path: 'localsearch-plus',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LocalsearchPlus />
      </Suspense>
    ),
  },
  {
    path: 'localsearch',
    element: (
      <Suspense fallback={<PageLoader />}>
        <LocalsearchPlus />
      </Suspense>
    ),
  },
  {
    path: 'google-ads',
    element: (
      <Suspense fallback={<PageLoader />}>
        <GoogleAds />
      </Suspense>
    ),
  },
  {
    path: 'seo',
    element: (
      <Suspense fallback={<PageLoader />}>
        <SEO />
      </Suspense>
    ),
  },
  {
    path: 'social-media',
    element: (
      <Suspense fallback={<PageLoader />}>
        <SocialMedia />
      </Suspense>
    ),
  },
  {
    path: 'website',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Websites />
      </Suspense>
    ),
  },
];

export default performanceRoutes;
