import { relayStylePagination } from '@apollo/client/utilities';
import { create } from '../lib/client';
import config from '../config';
import auth from './auth';

const { api } = config;

const typePolicies = {
  Profile: {
    fields: {
      contacts: {
        merge: (existing, incoming) => incoming,
      },
      media: {
        merge: (existing, incoming) => incoming,
      },
      tradingHours: {
        merge: (existing, incoming) => incoming,
      },
      amenities: {
        merge: (existing, incoming) => incoming,
      },
      products: {
        merge: (existing, incoming) => incoming,
      },
      categories: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
  Query: {
    fields: {
      reviews: relayStylePagination(['sort', 'sortDirection', 'listingId']),
      listingPosts: relayStylePagination(['listingId', 'state']),
      enquiries: relayStylePagination(['listingId']),
      quoteListings: relayStylePagination(['listingId']),
      profileHistory: relayStylePagination(['profileId', 'startAt', 'endAt']),
    },
  },
};

const instance = create({ api, auth, typePolicies });

export default instance;
