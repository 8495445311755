import { useContext } from 'react';
import useMedia from 'ls-common-client/src/hooks/useMedia';
import { ThemeContext } from 'styled-components';
import useSideBar from './useSideBar';
import useAccountProfileSearch from './useAccountProfileSearch';
import useContactUsDialog from './useContactUsDialog';

const useGlobalContextValues = () => {
  const theme = useContext(ThemeContext);
  const { mediaQueries } = theme as any;

  const media: Media = useMedia(mediaQueries);
  const accountProfileSearch = useAccountProfileSearch();
  const sideBar = useSideBar();
  const contactUsDialog = useContactUsDialog();

  return {
    theme,
    media,
    accountProfileSearch,
    sideBar,
    contactUsDialog,
  };
};

interface Media {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  device: 'desktop' | 'tablet' | 'mobile';
}

export default useGlobalContextValues;
export type GlobalContextValue = ReturnType<typeof useGlobalContextValues>;
