import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Context as GlobalContext } from '../GlobalContext';
import useAuthorisation from './hooks/useAuthorisation';
import { AwsAccount } from '../../__generated__/graphql';
import useUser from '../../hooks/useUser';
import { awsAccount as awsAccountQuery } from '../../graphql/queries';
import client from '../../services/client';
import { NotificationsContextProvider } from '../NotificationsContext';

interface ManageAccountContextTypes {
  authorisation?: ReturnType<typeof useAuthorisation>;
  media: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  };
  awsAccount: { awsAccount?: AwsAccount; loading: boolean };
}

interface ProviderProps {
  children: ReactNode;
}

const getAwsAccount = async (id?: string) => {
  if (!id) {
    return undefined;
  }

  const { data } = await client.query({
    query: awsAccountQuery,
    variables: {
      id,
    },
    errorPolicy: 'all',
  });
  return data?.awsAccount;
};

const Context = createContext<ManageAccountContextTypes>({
  authorisation: {
    loading: false,
    isAuthorised: false,
    hasRouteAccess: () => false,
  },
  media: {
    mobile: false,
    tablet: false,
    desktop: false,
  },
  awsAccount: {
    loading: false,
    awsAccount: undefined,
  },
});

const Provider = ({ children }: ProviderProps) => {
  const { awsAccountId } = useParams();
  const { media, ...globalValues } = useContext(GlobalContext);
  const authorisation = useAuthorisation();
  const { user, isStaff, isPersoniv } = useUser();
  const { awsAccountRoles } = user || {};

  const [awsAccount, setAwsAccount] = useState<AwsAccount>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isStaff || isPersoniv) {
      (async () => {
        setLoading(true);
        const account = await getAwsAccount(awsAccountId);
        setAwsAccount(account as AwsAccount);
        setLoading(false);
      })();
    } else {
      const activeAccount = awsAccountRoles?.find(
        role => role?.node?.awsAccountId === awsAccountId
      );
      setAwsAccount(activeAccount?.node?.awsAccount as AwsAccount);
    }
  }, [awsAccountRoles, awsAccountId, isStaff, isPersoniv]);

  const value = useMemo(
    () => ({
      ...globalValues,
      media,
      authorisation,
      awsAccount: { awsAccount, loading },
    }),
    [globalValues, authorisation, awsAccount]
  );

  return (
    <Context.Provider value={value}>
      <NotificationsContextProvider>{children}</NotificationsContextProvider>
    </Context.Provider>
  );
};

export { Provider, Context };
